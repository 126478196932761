import React, { FC, useEffect, useState } from "react";

import { CkModal } from "../../../CkUI";

import {
  getInactiveAccount,
  getLocalUserState,
  getUser,
} from "../../../stores/selectors";
import {
  clearInactiveAccount,
  fetchUserStatus,
  logoutUser,
  resendVerificationEmail,
  setLocalStatus,
} from "../../../stores/Slice/userStatusSlice";

import { useDispatch, useSelector } from "react-redux";

import "./styles.css";

interface IStatusModalProps {}

const StatusModal: FC<IStatusModalProps> = ({}) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const status = useSelector(getLocalUserState);
  const inactiveAccount = useSelector(getInactiveAccount);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const logout = () => dispatch(logoutUser());

  const init = async () => {
    if (user) {
      Promise.resolve(dispatch(fetchUserStatus(user.email)))
        .then((res: any) => {})
        .catch((e) => {
          setIsVisible(false);
          logout();
          console.info("e: ", e);
        });
    }
  };

  useEffect(() => {
    if (status !== undefined) {
      setIsVisible(true);
      if (status === "REGTY") {
        localStorage.removeItem("access_token");
      }
    } else {
      setIsVisible(false);
    }
  }, [status]);

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user]);

  const closeModal = () => {
    if (status === "PLSACTIVATE") {
      setIsVisible(false);
      logout();
    } else {
      setIsVisible(false);
    }
    dispatch(setLocalStatus(undefined));
  };

  const buttonText = () => {
    switch (status) {
      case "WELCOME":
        return "Comenzar";
      default:
        return "Ir a inicio";
    }
  };

  const infoModal = () => {
    switch (status) {
      case "REGTY":
        return (
          <>
            <p className="title">¡Gracias por registrarte!</p>
            <p className="info">
              {"En breve recibirás un correo \n con el que podrás activar tu cuenta."}
            </p>
          </>
        );
      case "PLSACTIVATE":
        return (
          <>
            <p className="title">Por favor, activa tu cuenta</p>
            <p className="info">
              {"Solo sigue el enlace de confirmación \n enviado a tu correo."}
            </p>
          </>
        );
      case "WELCOME":
        return (
          <>
            <p className="title">¡Bienvenido a CarKer™!</p>
            <p className="info">Tu cuenta ha sido activada exitosamente.</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <CkModal
      className="status-banner-modal"
      zIndex={5000}
      footer={null}
      open={isVisible}
      onCancel={() => {
        closeModal();
        dispatch(clearInactiveAccount());
      }}
      closable={true}
      primaryAction={{
        label: buttonText(),
        onClick: () => closeModal(),
        block: true,
      }}
      {...(status === "PLSACTIVATE"
        ? {
            secondaryAction: {
              label: "Reenviar correo",
              onClick: () => {
                closeModal();
                dispatch(
                  resendVerificationEmail({
                    email: inactiveAccount.email,
                    password: inactiveAccount.password,
                  })
                );
                dispatch(clearInactiveAccount());
              },
            },
          }
        : {})}
      actionButtonsDirection={"column"}
    >
      {infoModal()}
    </CkModal>
  );
};
export default StatusModal;

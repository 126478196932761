/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, memo } from "react";
import { message, Form } from "antd";
import { CkInput, CkModal } from "../../../../CkUI";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  fetchUserStatus,
  setInactiveEmail,
  setInactivePassword,
  setUser,
} from "../../../../stores/Slice/userStatusSlice";
import {
  encryptHMAC,
  gtmAddLayer,
  gtmSetId,
  useIsMobileScreen,
} from "../../../../utilities";
import { PromoCodesAPI } from "../../../../services";
import { AuthRest } from "../../../../auth/SignInAuth";
import { handleNoSpaceKeyPress } from "../../../../utilities/onKeyPressUtilities";
import "./styles.css";
import CkInputPassword from "../../../../CkUI/components/molecules/CkInputPassword";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL as string;
const authRest = new AuthRest();

interface IProps {
  setChangePasswordModal: Function;
  setSignupModal: Function;
  setLoginModal: Function;
  loginModal: boolean;
}

function removeSpaces(input: string): string {
  return input.replace(/\s+/g, "");
}

const LoginModal: FC<IProps> = ({
  setChangePasswordModal,
  setSignupModal,
  setLoginModal,
  loginModal
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileScreen(768);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [signinForm] = Form.useForm();

  const resetForms = () => {
    signinForm.resetFields();
    setPassword("");
    setEmail("");
  };

  const login = async () => {
    // Let's validate the fields
    const formData = await signinForm
      .validateFields()
      .then((values) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent execution.
    if (formData === false) return false;

    const formattedEmail = removeSpaces(formData.email);

    signinForm.setFieldsValue({
      email: formattedEmail,
    });

    setLoading(true);

    Promise.resolve(dispatch(fetchUserStatus(formattedEmail)))
      .then(async (res: any) => {
        if (res && res.payload === true) {
          // Perform login request
          const loginResponse = await authRest
            .login({
              username: formattedEmail,
              password: formData.password,
            })
            .then((response) => response);
          // let's check if there are some error
          if (loginResponse.status === false) {
            message.error(loginResponse.message);
          } else {
            // set user in context
            dispatch(setUser(loginResponse.data.user));

            // track login event, if available tracking
            gtmAddLayer({
              User_ID: loginResponse.data.user.id.toString(),
              account_type: "Customer",
              event: "user_login",
            });

            if (loginResponse.data.user.isNew === true) {
              PromoCodesAPI.checkUserInvite({
                userInvitedID: parseInt(loginResponse.data.user.id),
                userInvitedEmail: loginResponse.data.user.email,
              })
                .then((result) => {})
                .catch((err) => {});
            }

            // Close modal, drawer and clear form
            setLoginModal(false);
            signinForm.resetFields();
          }

          setLoading(false);
        } else if (res && res.payload === false) {
          const encryptedPass = encryptHMAC(formData.password, true);
          dispatch(setInactiveEmail(formData.email));
          dispatch(setInactivePassword(encryptedPass));
          resetForms();
          setLoginModal(false);
          setLoading(false);
        } else {
          message.error("El usuario o contraseña indicados no es válido");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const createAccountAction = (e) => {
    setLoginModal(false);
    setSignupModal(true);
    gtmSetId(e.currentTarget);
  };

  const loginAction = (e) => {
    login();
    gtmSetId(e.currentTarget);
  };

  const forgotAction = (e) => {
    setLoginModal(false);
    setChangePasswordModal(true);
    gtmSetId(e.currentTarget);
  };

  return (
    <CkModal
      zIndex={3200}
      closable
      destroyOnClose
      title={"¡Hola de nuevo!"}
      footer={null}
      open={loginModal}
      className="login-modal"
      actionButtonsDirection="triangle"
      onCancel={() => {
        setLoginModal(false);
        resetForms();
      }}
      secondaryAction={{
        label: "Crear cuenta",
        onClick: createAccountAction,
        id: "HEADER015",
        order: isMobile ? 3 : 1,
      }}
      primaryAction={{
        label: "Inicia sesión",
        onClick: loginAction,
        loading: loading,
        id: "HEADER014",
        order: 2,
      }}
      tertiaryAction={{
        label: isMobile
          ? "Recuperar contraseña"
          : "Recuperar usuario o contraseña",
        onClick: forgotAction,
        id: "HEADER013",
        order: isMobile ? 1 : 3,
      }}
    >
      <div className="content-wrapper">
        <p className="option-label">Inicia sesión con tu email:</p>

        <Form form={signinForm} className="nav-forms">
          <CkInput
            // className="title-16px black"
            variant="default"
            name="email"
            extra="Nombre de usuario o email"
            rules={[
              {
                required: true,
                message: "Ingresa el usuario o el correo electrónico",
              },
              {
                message: "Debes ingresar un correo electrónico",
                validator: (rule, value, callback) => {
                  const emailRegex =
                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                  const sanitizedValue = value.replace(/\s/g, "");
                  if (sanitizedValue.match(emailRegex)) {
                    callback();
                  } else {
                    callback("Escribe un correo válido");
                  }
                },
              },
            ]}
            inputProps={{
              placeholder: "Escribe tu nombre de usuario o email",
              value: email,
              onKeyPress: handleNoSpaceKeyPress,
              onChange: (e) => setEmail(e.target.value),
              onPressEnter: login,
              maxLength: 256,
            }}
          />

          <CkInputPassword
            variant="default"
            name="password"
            extra="Contraseña"
            rules={[
              {
                required: true,
                message: "Ingresa la contraseña",
              },
            ]}
            inputProps={{
              style: { fontSize: "16px" },
              placeholder: "Escribe tu contraseña",
              value: password,
              onChange: (e) => setPassword(e.target.value),
              onPressEnter: login,
            }}
          />
          <p className="black-70" style={{ fontSize: "12px" }}>
            Reconoce mayúsculas y minúsculas
          </p>
        </Form>
      </div>
    </CkModal>
  );
};

export default memo(LoginModal);

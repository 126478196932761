import React, { FC, useState, memo } from "react";
import loadable from "@loadable/component";
import { Link } from "react-router-dom";
import { Layout, Avatar, Button, Drawer, Divider, Modal, Input } from "antd";
import { getImageBaseUrl, gtmSetId } from "../../../../utilities";
import { User } from "../../../../models";

import {
  CkUserSvg,
  CkMenuIcon,
  CKCloseIcon,
  CkBlogIcon,
  CkHowItWorksIcon,
  CkWorkshopCommitmentsIcon,
  CkFaqIcon,
  CkContactIcon,
  WhatsappIconUncolored,
  CkPhoneIcon,
  CkMailIcon,
} from "../../../../assets/SvgIcons";
import "./styles.css";
import { CkButton, CkIcon } from "../../../../CkUI";
const Logo = getImageBaseUrl("logos/logo.svg");

const { Header } = Layout;
const NotificationBell = loadable(() => import("../NotificationBell"));

interface IProps {
  user: User | undefined;
  sasToken: string | undefined;
  logout: Function;
  setLoginModal: Function;
  setSignupModal: Function;
  triggerAntLayoutScroll: Function;
  setGuestAppointmentCode: Function;
}

const MobileNavbar: FC<IProps> = ({
  user,
  sasToken,
  logout,
  setLoginModal,
  setSignupModal,
  triggerAntLayoutScroll,
  setGuestAppointmentCode,
}) => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [showAppoTrackingModal, setShowAppoTrackingModal] =
    useState<boolean>(false);
  const [guestAppointmentCodeInput, setGuestAppointmentCodeInput] =
    useState("");

  const HandleAvatarOptions = () => {
    setShowMenuMobile(false);
    document.body.classList.remove("no-scroll");
    document.body.style.removeProperty("overflow-y");
  };

  const MenuMobileRegisteredUser = () => {
    return (
      <>
        <Link
          to="/profile"
          id="HEADER021"
          onClick={(e) => {
            HandleAvatarOptions();
            gtmSetId(e.currentTarget);
            triggerAntLayoutScroll();
          }}
        >
          Editar perfil
        </Link>
        <CkButton
          id="HEADER022"
          onClick={(e) => {
            logout();
            setShowMenuMobile(false);
            triggerAntLayoutScroll();
            window.location.href = "/";
          }}
          color="white"
          type="text"
          block={false}
          triggerGTMOnClick
        >
          Cerrar sesión
        </CkButton>
      </>
    );
  };

  const MenuMobileNonRegisteredUser = () => (
    <>
      <CkButton
        data-gtm-id="HEADER011"
        onClick={(e) => {
          setLoginModal(true);
          setShowMenuMobile(false);
          triggerAntLayoutScroll();
        }}
        color="white"
        type="text"
        block={false}
      >
        Inicia sesión
      </CkButton>
      <CkButton
        data-gtm-id="HEADER016"
        onClick={(e) => {
          setSignupModal(true);
          setShowMenuMobile(false);
          triggerAntLayoutScroll();
        }}
        color="white"
        type="text"
        block={false}
        triggerGTMOnClick
      >
        Crea tu cuenta
      </CkButton>
    </>
  );

  return (
    <>
      <Header className="mobile-navbar">
        <div className="navigation-options">
          <Button
            aria-label="Abrir menú"
            /* icon={<img src={MenuIcon} alt="menu-icon" width={32} height={32} />} */
            icon={<CkMenuIcon />}
            onClick={() => {
              setMobileMenu(true);
              document.body.classList.add("no-scroll");
            }}
          />
        </div>
        <Link to="/">
          <img
            id="HEADER001"
            src={Logo}
            alt="CarKer logo"
            width={117}
            height={41}
            loading="lazy"
            onClick={(e) => {
              gtmSetId(e.currentTarget);
              triggerAntLayoutScroll();
            }}
          />
        </Link>
        <div className="navigation-options">
          {!user ? (
            <Button
              aria-label="Menú de usuario"
              size="large"
              className="mobile-block-nav icon-double"
              icon={<CkUserSvg />}
              onClick={() => {
                setShowMenuMobile(true);
                document.body.classList.add("no-scroll");
              }}
            />
          ) : (
            <>
              <NotificationBell />
              <Button
                size="large"
                aria-label="Menú de usuario"
                onClick={() => {
                  setShowMenuMobile(true);
                  document.body.classList.add("no-scroll");
                }}
              >
                {user && user.photoUrl.trim() !== "" && sasToken ? (
                  <Avatar
                    aria-label="Ver opciones de usuario"
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                    src={`${user?.photoUrl}?${sasToken}`}
                    onError={() => true}
                  />
                ) : (
                  <CkUserSvg aria-label="Ver opciones de usuario" />
                )}
              </Button>
            </>
          )}
        </div>
      </Header>
      <Drawer
        destroyOnClose
        forceRender
        placement="right"
        className="menu-mobile right"
        open={showMenuMobile}
        zIndex={3150}
        onClose={() => {
          setShowMenuMobile(false);
          document.body.classList.remove("no-scroll");
        }}
        closable
        maskClosable
        closeIcon={<CKCloseIcon />}
      >
        {user ? <MenuMobileRegisteredUser /> : <MenuMobileNonRegisteredUser />}
      </Drawer>
      <Drawer
        // ----------------Mobile Login -------------------------
        height="fit-content"
        placement="left"
        open={mobileMenu}
        className="menu-mobile left"
        zIndex={3150}
        onClose={() => {
          setMobileMenu(false);
          document.body.classList.remove("no-scroll");
        }}
        closable
        maskClosable
        closeIcon={<CKCloseIcon />}
        bodyStyle={{
          paddingLeft: "0px",
          paddingRight: "0px",
          height: "fit-content",
        }}
      >
        {!user && (
          <Link
            to={{}}
            className="link-nav standar"
            onClick={() => {
              setMobileMenu(false);
              triggerAntLayoutScroll();
              setShowAppoTrackingModal(true);
            }}
          >
            <CkIcon name="appo-tracking" width="" height="" fill="" />
            Estatus de la cita
          </Link>
        )}
        <Link
          to={{
            pathname: "/blog/1",
          }}
          className="link-nav standar"
          onClick={(e) => {
            setMobileMenu(false);
            triggerAntLayoutScroll();
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
        >
          <CkBlogIcon />
          Blog
        </Link>
        <Link
          id="HEADER007"
          to={{
            pathname: "/quienes-somos",
          }}
          className="link-nav standar"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            triggerAntLayoutScroll();
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
        >
          <CkHowItWorksIcon />
          ¿Quiénes somos?
        </Link>
        <Link
          id="HEADER006"
          to={{
            pathname: "/talleres-afiliados",
          }}
          className="link-nav standar"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            triggerAntLayoutScroll();
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
          aria-label="Talleres afiliados"
        >
          <CkWorkshopCommitmentsIcon />
          Talleres afiliados
        </Link>
        <Link
          id="HEADER008"
          to={{
            pathname: "/preguntas-frecuentes",
            hash: "frequentQuestionsContain",
          }}
          className="link-nav standar"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            triggerAntLayoutScroll();
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
        >
          <CkFaqIcon />
          Preguntas frecuentes
        </Link>
        <Divider
          dashed
          style={{
            backgroundColor: "transparent",
            borderColor: "white",
            margin: "0.4rem 0px 1.1rem 0px",
          }}
        />
        <Link
          id="HEADER009"
          to={{ pathname: "/contacto" }}
          className="link-nav standar"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            triggerAntLayoutScroll();
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
        >
          <CkContactIcon />
          Contáctanos
        </Link>
        <a
          id="HEADER029"
          href="https://wa.me/+525564359958"
          className="link-nav standar contact-option"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
          style={{ marginLeft: 31 }}
        >
          <WhatsappIconUncolored />
          Escribir por WhatsApp
        </a>
        <a
          id="HEADER030"
          href="tel:5522820800"
          className="link-nav standar contact-option"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
          style={{ marginLeft: 31 }}
        >
          <CkPhoneIcon />
          Llamar al 55 2282 0800
        </a>
        <a
          id="HEADER031"
          href="mailto:soporte@carker.com.mx"
          className="link-nav standar contact-option"
          onClick={(e) => {
            gtmSetId(e.currentTarget);
            setMobileMenu(false);
            document.body.style.removeProperty("overflow-y");
            document.body.classList.remove("no-scroll");
          }}
          style={{ marginLeft: 31 }}
        >
          <CkMailIcon />
          Enviar correo electrónico
        </a>
      </Drawer>
      <Modal
        centered
        open={showAppoTrackingModal}
        destroyOnClose={true}
        forceRender={true}
        maskClosable={false}
        closable={false}
        footer={null}
        className="appo-tracking-modal"
      >
        <div className="appo-tracking-modal">
          <div
            className="close-modal"
            onClick={() => setShowAppoTrackingModal(false)}
          >
            <CKCloseIcon />
          </div>
          <div className="header-text">
            <p>Usa el número de cita que obtuviste por</p>
            <p>correo o celular para dar seguimiento a tu cita.</p>
          </div>
          <div className="input-container">
            <div className="input-title">Código de cita</div>
            <Input
              placeholder="Ingresa el código"
              onChange={(inputElement: any) => {
                const target = inputElement.target as HTMLInputElement;
                setGuestAppointmentCodeInput(target.value);
              }}
              style={{ borderRadius: "10px", minWidth: "240px" }}
            />
            <CkButton
              color="primary"
              onClick={() => {
                setGuestAppointmentCode(guestAppointmentCodeInput);
              }}
            >
              Ver estatus
            </CkButton>
          </div>
          <p
            className="appo-tracking-footer"
            onClick={(e) => {
              setShowAppoTrackingModal(false);
              setLoginModal(true);
              gtmSetId(e.currentTarget);
              triggerAntLayoutScroll();
            }}
          >
            o <span>inicia sesión</span> para ver tus citas
          </p>
        </div>
      </Modal>
    </>
  );
};

export default memo(MobileNavbar);

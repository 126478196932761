/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, memo, useEffect } from "react";
/* import loadable from "@loadable/component"; */
import { message, Form } from "antd";
import { CkCheckbox, CkInput, CkModal } from "../../../../CkUI";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { gtmAddLayer, gtmSetId } from "../../../../utilities";
import ReCaptcha from "../../../ReCaptcha";
import { AuthRest } from "../../../../auth/SignInAuth";
import { setLocalStatus } from "../../../../stores/Slice/userStatusSlice";

import {
  handleKeyPress,
  handleNoSpaceKeyPress,
} from "../../../../utilities/onKeyPressUtilities";

import "./styles.css";

/* const SocialButtons = loadable(() => import("../SocialButtons")); */

axios.defaults.baseURL = process.env.REACT_APP_BASEURL as string;

const authRest = new AuthRest();

interface IProps {
  setSignupModal: Function;
  signupModal: boolean;
  userContext?: any;
}

function removeTrailingSpaces(input: string): string {
  let result = input.replace(/\s+/g, " ");
  return result.replace(/\s+$/, "");
}

function removeSpaces(input: string): string {
  return input.replace(/\s+/g, "");
}

const SignUpModal: FC<IProps> = ({
  setSignupModal,
  signupModal,
  userContext,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [recaptchaValidation, setRecaptchaValidation] =
    useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);

  const [signupForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  useEffect(() => {
    if (userContext) {
      setName(userContext.name);
      setLastname(userContext.lastName);
      setEmail(userContext.email);
      signupForm.setFieldsValue({
        name: userContext.name,
        lastname: userContext.lastName,
        email: userContext.email,
      });
    }
  }, [signupModal, userContext]);

  const resetForms = () => {
    signupForm.resetFields();
    changePasswordForm.resetFields();
    setName("");
    setLastname("");
    setPassword("");
    setCheckPassword("");
    setEmail("");
    setCheck(false);
  };

  const signUp = async () => {
    // Let's validate the fields
    const formData = await signupForm
      .validateFields()
      .then((values) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent excecution.
    if (formData === false) return false;

    const formattedName = removeTrailingSpaces(formData.name);
    const formattedLastName = removeTrailingSpaces(formData.lastname);
    const formattedEmail = removeSpaces(formData.email);

    signupForm.setFieldsValue({
      name: formattedName,
      lastname: formattedLastName,
      email: formattedEmail,
    });

    const payload = {
      firstName: formattedName,
      lastName: formattedLastName,
      email: formattedEmail,
      password: formData.password,
    };

    setLoading(true);

    // Perform signUp request
    const signupResponse = await authRest
      .signUp({ ...payload }, true)
      .then((response) => response);

    if (signupResponse.status === false) {
      message.error(signupResponse.message);
    } else {
      // set user in context
      // setUser(signupResponse.data.user);

      // track login event, if available tracking
      gtmAddLayer({
        User_ID: signupResponse.data.user.id.toString(),
        account_type: "Customer",
        event: "user_register",
      });

      // PromoCodesAPI.checkUserInvite({
      //   userInvitedID: parseInt(signupResponse.data.user.id),
      //   userInvitedEmail: signupResponse.data.user.email,
      // })
      //   .then((result) => {})
      //   .catch((err) => {});

      // Close modal, drawe and clear form
      setSignupModal(false);
      signupForm.resetFields();

      // Set user state like REGTY
      dispatch(setLocalStatus("REGTY"));
    }

    setLoading(false);
  };

  return (
    <CkModal
      zIndex={3200}
      closable
      destroyOnClose
      className="signup-modal"
      title="Crea una cuenta"
      footer={null}
      visible={signupModal}
      onCancel={() => {
        setSignupModal(false);
        resetForms();
      }}
      actionButtonsDirection={{
        responsive: "column",
        desktop: "column",
      }}
      primaryAction={{
        label: "Crear cuenta",
        id: "HEADER019",
        onClick: (e) => {
          signUp();
          gtmSetId(e.currentTarget);
        },
        loading: loading,
        disabled:
          !recaptchaValidation ||
          !check ||
          !name ||
          !lastname ||
          !email ||
          !password ||
          !checkPassword ||
          password !== checkPassword,
      }}
    >
      <div className="content-wrapper">
        {/* 
        <p className="option-label">Con tus redes sociales</p>
        <SocialButtons action="signup" />
        <p className="option-label" style={{ marginTop: "10px" }}>
          O con tu correo:
        </p>
        <Divider dashed /> 
        */}
        <p style={{ margin: "10px 0 0 0",fontSize: "14px", fontWeight: "400", color: "#AAAAAA" }}>
          Por favor completa todos los campos
        </p>
        <Form form={signupForm} className="nav-forms">
          <CkInput
            variant="default"
            name="name"
            extra="Nombre(s)"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Ingresa el nombre de usuario",
              },
              {
                pattern:
                  /^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g,
                message: "Escribe por lo menos un nombre válido",
              },
            ]}
            inputProps={{
              placeholder: "Escribe tu(s) nombre(s)",
              value: name,
              onKeyPress: handleKeyPress,
              onChange: (e) => setName(e.target.value),
              maxLength: 50,
            }}
          />

          <CkInput
            className={
              !name ||
              !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g.test(
                name
              )
                ? "title-16px black-disabled"
                : "title-16px black"
            }
            variant="default"
            name="lastname"
            extra="Apellido(s)"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Ingresa el apellido de usuario",
              },
              {
                pattern:
                  /^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g,
                message: "Escribe por lo menos un apellido válido",
              },
            ]}
            inputProps={{
              placeholder: "Escribe tu(s) apellido(s)",
              value: lastname,
              onKeyPress: handleKeyPress,
              onChange: (e) => setLastname(e.target.value),
              maxLength: 50,
              disabled:
                !name ||
                !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g.test(
                  name
                ),
            }}
          />

          <CkInput
            className={
              !lastname ||
              !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g.test(
                lastname
              )
                ? "title-16px black-disabled"
                : "title-16px black"
            }
            variant="default"
            name="email"
            extra="Correo electrónico"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Ingresa el correo electrónico",
              },
              {
                // pattern:
                //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)+$/,
                // type: "email",
                message: "Debes ingresar un correo electrónico",
                validator: (rule, value, callback) => {
                  const emailRegex =
                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                  const sanitizedValue = value.replace(/\s/g, "");
                  if (sanitizedValue.match(emailRegex)) {
                    callback();
                  } else {
                    callback("Escribe un correo válido");
                  }
                },
              },
            ]}
            inputProps={{
              placeholder: "Escribe tu correo",
              value: email,
              type: "email",
              onKeyPress: handleNoSpaceKeyPress,
              onChange: (e) => setEmail(e.target.value),
              maxLength: 256,
              disabled:
                !lastname ||
                !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ ]*)*$/g.test(
                  lastname
                ),
            }}
          />

          <CkInput
            className={
              !email ||
              !/^\s*[ A-Za-z0-9._%+-]+@\s?[A-Za-z0-9]+(\s?\.[A-Za-z0-9]+)*\s*$/.test(
                email
              )
                ? "title-16px black-disabled"
                : "title-16px black"
            }
            variant="default"
            name="password"
            extra="Contraseña"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Ingresa la contraseña",
              },
              {
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#?!@$%^.,;:&*+¿¡|°"()/={}\[\]]).{8,}$/,
                message:
                  "Debe tener al menos ocho caracteres, minúsculas, mayúsculas, y por lo menos un número y un símbolo",
              },
            ]}
            inputProps={{
              placeholder: "Escribe tu contraseña",
              value: password,
              type: "password",
              onChange: (e) => setPassword(e.target.value),
              disabled:
                !email ||
                !/^\s*[ A-Za-z0-9._%+-]+@\s?[A-Za-z0-9]+(\s?\.[A-Za-z0-9]+)*\s*$/.test(
                  email
                ),
            }}
          />

          {!password && (
            <p className="black-70" style={{ fontSize: "12px" }}>
              Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por lo
              menos un número y un símbolo
            </p>
          )}

          <CkInput
            className={
              !email ||
              !/^\s*[ A-Za-z0-9._%+-]+@\s?[A-Za-z0-9]+(\s?\.[A-Za-z0-9]+)*\s*$/.test(
                email
              )
                ? "title-16px black-disabled"
                : "title-16px black"
            }
            variant="default"
            name="checkPassword"
            extra="Comprueba tu contraseña"
            hasFeedback
            validateStatus={`${
              checkPassword
                ? checkPassword === password
                  ? "success"
                  : "error"
                : ""
            }`}
            rules={[
              {
                required: true,
                message: "Ingresa de nuevo la contraseña",
              },
            ]}
            inputProps={{
              placeholder: "Escribe de nuevo tu contraseña",
              value: password,
              type: "password",
              onChange: (e) => setCheckPassword(e.target.value),
              disabled:
                !email ||
                !/^\s*[ A-Za-z0-9._%+-]+@\s?[A-Za-z0-9]+(\s?\.[A-Za-z0-9]+)*\s*$/.test(
                  email
                ),
            }}
          />

          <ReCaptcha
            styles={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
            setRecaptchaValidation={setRecaptchaValidation}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0px",
              justifyContent: "center",
            }}
          >
            <CkCheckbox
              checkboxProps={{
                style: {
                  fontSize: "14px",
                },
                checked: check,
                onChange: (e) => setCheck(e.target.checked),
              }}
            >
              He leído y estoy de acuerdo con los
              <Link
                id="HEADER018"
                to="/terminosycondiciones"
                target="_blank"
                className="terms-link"
                type="link"
                onClick={(e) => gtmSetId(e.currentTarget)}
              >
                <span>T y C</span>
              </Link>
            </CkCheckbox>
          </div>
        </Form>
      </div>
    </CkModal>
  );
};

export default memo(SignUpModal);

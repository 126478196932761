/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, memo } from "react";
import { Input, message, Form } from "antd";
import { CkInput, CkModal } from "../../../../CkUI";
import axios from "axios";
import { UserAPI } from "../../../../services/axios";
import { encryptHMAC, hashSha256 } from "../../../../utilities/Encryption";

import "./styles.css";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL as string;

interface IProps {
  setChangePasswordModal: Function;
  changePasswordModal: boolean;
}

const ChangePasswordModal: React.FC<IProps> = ({
  setChangePasswordModal,
  changePasswordModal,
}) => {
  const [loadingChangePass, setLoadingChangePass] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [changePasswordForm] = Form.useForm();

  const [OTPSended, setOTPSended] = useState<boolean>(false);
  const [blockSendEmail, setBlockSendEmail] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const otpRef = useRef<any>([]);

  const resetForms = () => {
    changePasswordForm.resetFields();
    setPassword("");
    setCheckPassword("");
    setEmail("");
    setOtp(["", "", "", "", "", ""]);
    setOTPSended(false);
    setEmailVerified(false);
  };

  const checkUser = () => {
    changePasswordForm
      .validateFields()
      .then(async (values) => {
        let userCheckResult = await UserAPI.checkUser(values.email);
        if (
          userCheckResult.data.isB2CUser &&
          userCheckResult.data.isEndConsumer
        ) {
          SendVerificationCode(values.email);
        } else {
          message.error(
            "Este correo electrónico no se encuentra registrado en nuestra plataforma."
          );
        }
      })
      .catch((err) => {
        message.warning("Verifica todos los campos del formulario.");
      });
  };

  const SendVerificationCode = (email: string) => {
    setBlockSendEmail(true);
    axios
      .post("/notifications/verificationCodeNotifications", {
        emailAddress: email,
        isEndConsumer: true,
      })
      .then((data) => {
        message.success(
          "Se envió el código de verificación, revisa tu correo electrónico"
        );
        localStorage.setItem("OTP", data.data.verificationCode);
        setOTPSended(true);
        setTimeout(() => {
          localStorage.removeItem("OTP");
        }, 900000);
        setBlockSendEmail(false);
      })
      .catch(() => {
        message.error("No se pudo enviar el código de verificación");
      });
  };

  const VerifyOTP = (paste?: string[]) => {
    const hashedOtp = hashSha256((paste || otp).join(""));
    if (hashedOtp === localStorage.getItem("OTP")) {
      setEmailVerified(true);
      message.success("Correo electrónico comprobado, puede continuar");
      localStorage.removeItem("OTP");
    } else {
      message.error("El código de verificación es inválido");
    }
  };

  const finish = () => {
    changePasswordForm
      .validateFields()
      .then((values) => {
        setLoadingChangePass(true);
        let ep = encryptHMAC(values.password, true);
        axios
          .put("/b2c/users?isEndConsumer=true", {
            emailaddress: email,
            password: ep,
          })
          .then((result) => {
            message.success(
              "Contraseña cambiada con éxito. Puedes iniciar sesión con tu nueva contraseña."
            );
            setLoadingChangePass(false);
            setChangePasswordModal(false);
          })
          .catch((err) => {
            alert("Ha ocurrido un error, vuelva a intentarlo.");
          });
      })
      .catch((err) => {
        message.warning("Verifica todos los campos del formulario.");
      });
  };

  const changeFocus = (index: number) => {
    if (index < 5) otpRef.current[index + 1].focus();
  };

  return (
    <CkModal
      zIndex={3200}
      closable
      destroyOnClose
      className="change-password-modal"
      title={emailVerified ? "Cambiar contraseña" : "¡A todos nos pasa!"}
      footer={null}
      visible={changePasswordModal}
      onCancel={() => {
        setChangePasswordModal(false);
        resetForms();
      }}
      actionButtonsDirection={{
        responsive: "column",
        desktop: "column",
      }}
      primaryAction={
        emailVerified
          ? {
              label: "Establecer nueva contraseña",
              onClick: finish,
              disabled: password !== checkPassword || loadingChangePass,
              // loading: loadingChangePass,
            }
          : !OTPSended
          ? {
              label: "Envía código de verificación",
              onClick: checkUser,
              disabled: blockSendEmail,
            }
          : {
              label: "Verificar código",
              onClick: VerifyOTP,
            }
      }
      tertiaryAction={
        !emailVerified &&
        OTPSended && {
          label: "Reenviar código",
          onClick: checkUser,
        }
      }
    >
      <div className="content-wrapper">
        {emailVerified ? (
          <div>
            <p
              className="blue"
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              Establece una nueva contraseña
            </p>
            <Form form={changePasswordForm} className="nav-forms">
              <CkInput
                variant="default"
                name="password"
                extra="Contraseña"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ingresa la contraseña",
                  },
                  {
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^.,;:&*-+¿¡|°"()/={}\[\]]).{8,}$/,
                    message:
                      "Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por lo menos un número y un símbolo",
                  },
                ]}
                inputProps={{
                  placeholder: "Escribe tu contraseña",
                  value: password,
                  type: "password",
                  onChange: (e) => setPassword(e.target.value),
                }}
              />
              {!password && (
                <p className="black-70" style={{ fontSize: "12px" }}>
                  Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y
                  por lo menos un número y un símbolo
                </p>
              )}
              <CkInput
                variant="default"
                name="checkPassword"
                extra="Comprueba tu contraseña"
                hasFeedback
                validateStatus={`${
                  checkPassword
                    ? checkPassword === password
                      ? "success"
                      : "error"
                    : ""
                }`}
                rules={[
                  {
                    required: true,
                    message: "Ingresa de nuevo la contraseña",
                  },
                ]}
                inputProps={{
                  placeholder: "Escribe de nuevo tu contraseña",
                  type: "password",
                  onChange: (e) => setCheckPassword(e.target.value),
                }}
              />
            </Form>
          </div>
        ) : (
          <div>
            <p
              className="blue"
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              {OTPSended
                ? "Escribe el código de verificación que enviamos a tu correo"
                : "Escribe tu correo y te enviaremos un código de verificación"}
            </p>
            <Form form={changePasswordForm} className="nav-forms">
              <CkInput
                hidden={OTPSended}
                variant="default"
                name="email"
                extra="Email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ingresa el correo electrónico",
                  },
                  {
                    // pattern:
                    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)+$/,
                    type: "email",
                    message: "Debes ingresar un correo electrónico",
                  },
                ]}
                inputProps={{
                  placeholder: "Escribe tu correo",
                  value: email,
                  type: "email",
                  onChange: (e) => setEmail(e.target.value),
                  maxLength: 256,
                }}
              />
            </Form>
            <div className="digits-input-container">
              <div>
                {[...Array(3)].map((_, index) => (
                  <Input
                    key={`otp${index}`}
                    ref={(el) => {
                      if (!otpRef.current[index]) {
                        if (index === 0) {
                          el?.input.addEventListener("paste", (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            let paste = (
                              event.clipboardData || window.clipboardData
                            )?.getData("text");
                            paste = paste.slice(0, 6).split("");
                            setOtp(paste);
                            changeFocus(4);
                            VerifyOTP(paste);
                          });
                        }
                      }
                      otpRef.current[index] = el;
                    }}
                    type="number"
                    size="large"
                    value={otp[index]}
                    className="digits-input"
                    onChange={(evt) => {
                      evt.preventDefault();
                      const temp = [...otp];
                      temp[index] = evt.target.value[0];
                      setOtp(temp);
                      if (evt.target.value.length > 0) changeFocus(index);
                    }}
                    hidden={!OTPSended}
                  />
                ))}
              </div>
              <div>
                {[...Array(3)].map((_, index) => (
                  <Input
                    type="number"
                    key={`otp${index + 3}`}
                    ref={(el) => (otpRef.current[index + 3] = el)}
                    size="large"
                    value={otp[index + 3]}
                    className="digits-input"
                    onChange={(evt) => {
                      evt.preventDefault();
                      const temp = [...otp];
                      temp[index + 3] = evt.target.value[0];
                      setOtp(temp);
                      if (evt.target.value.length > 0) changeFocus(index + 3);
                    }}
                    hidden={!OTPSended}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </CkModal>
  );
};

export default memo(ChangePasswordModal);

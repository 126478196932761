import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { serviceSAS } from "../../../stores";

const SasTokenRefresher = () => {
  const intervalID = useRef<NodeJS.Timer | null>(null);
  const dispatch = useDispatch();

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  useEffect(() => {
    getSasTokenAzure();
    intervalID.current = setInterval(getSasTokenAzure, 240000);
    return () => {
      intervalID.current && clearInterval(intervalID.current);
      intervalID.current = null;
    };
  }, []);

  return <></>
}

export default SasTokenRefresher;

import React, { FC, memo, useState } from "react";
import loadable from "@loadable/component";
import { Link } from "react-router-dom";
import { Layout, Menu, Avatar, Divider, Input } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { getImageBaseUrl, gtmSetId } from "../../../../utilities";
import { User } from "../../../../models";

import "./styles.css";
import { CkButton } from "../../../../CkUI";
import { useSelector } from "react-redux";
import { getNavigationSelectedKeys } from "../../../../stores";

const Logo = getImageBaseUrl("logos/logo.svg");
const phoneIco = getImageBaseUrl("Icon_Menu_Phone.png");
const whatsappIco = getImageBaseUrl("Icon_Menu_Whatsapp.png");
const MailIco = getImageBaseUrl("Icon_Menu_Email.png");

const { Header } = Layout;

const NotificationBell = loadable(() => import("../NotificationBell"));

const CkUserIcon = loadable(
  () => import("../../../../assets/SvgIcons/CkUserIcon")
);

interface IProps {
  user: User | undefined;
  sasToken: string | undefined;
  logout: Function;
  setLoginModal: Function;
  setSignupModal: Function;
  triggerAntLayoutScroll: Function;
  setGuestAppointmentCode: Function;
}

const DesktopNavbar: FC<IProps> = ({
  user,
  sasToken,
  logout,
  setLoginModal,
  setSignupModal,
  triggerAntLayoutScroll,
  setGuestAppointmentCode,
}) => {
  const selectedKeys = useSelector(getNavigationSelectedKeys);
  const [guestAppointmentCodeInput, setGuestAppointmentCodeInput] =
    useState("");

  return (
    <Header className="desktop-navbar">
      <Link to="/" aria-label={"Ir a la página principal"}>
        <img
          loading="lazy"
          id="HEADER001"
          src={Logo}
          alt="CarKer logo"
          width={137}
          height={48}
          onClick={(e) => {
            console.info("e.currentTarget", e.currentTarget);
            gtmSetId(e.currentTarget);
            triggerAntLayoutScroll();
          }}
        />
      </Link>
      <div className="navigation-options">
        <Menu
          mode="horizontal"
          className="web-menu menu-blue"
          disabledOverflow
          selectedKeys={selectedKeys}
        >
          <Menu.Item key="INICIO">
            <Link to="/" id="HEADERINICIO">
              Inicio
            </Link>
          </Menu.Item>
          {!user && (
            <SubMenu
              key="90"
              title="Estatus de cita"
              popupClassName="guest-status"
              className="opened"
              theme="light"
            >
              <Menu.Item
                key="91"
                style={{ lineHeight: "17px", whiteSpace: "normal" }}
              >
                <div
                  className="guest-status-header"
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  Usa el número de cita que obtuviste por correo o celular para
                  dar seguimiento a tu cita.
                </div>
              </Menu.Item>
              <Menu.Item key="92">
                <div
                  className="guest-status-code"
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  Código de cita
                </div>
              </Menu.Item>
              <Menu.Item key="93">
                <Input
                  placeholder="Ingresa el código"
                  onChange={(inputElement: any) => {
                    const target = inputElement.target as HTMLInputElement;
                    setGuestAppointmentCodeInput(target.value);
                  }}
                  onClick={(e: any) => {
                    // Prevents modal from closing when focusing the input field
                    e.stopPropagation();
                  }}
                  style={{ borderRadius: "10px", minWidth: "240px" }}
                />
              </Menu.Item>
              <Menu.Item key="94" style={{ marginTop: 10 }}>
                <CkButton
                  color="primary"
                  onClick={() => {
                    setGuestAppointmentCode(guestAppointmentCodeInput);
                  }}
                >
                  Ver estatus
                </CkButton>
              </Menu.Item>
              <Menu.Item key="99">
                <p
                  onClick={(e) => {
                    setLoginModal(true);
                    gtmSetId(e.currentTarget);
                    triggerAntLayoutScroll();
                  }}
                  className="guest-status-footer"
                >
                  o <span>inicia sesión</span> para ver tus citas
                </p>
              </Menu.Item>
            </SubMenu>
          )}
          <Menu.Item key="blog">
            <Link to="/blog/1" id="go-to-blog-desktop-link">
              Blog
            </Link>
          </Menu.Item>
          <SubMenu
            key="NOSOTROS"
            title="Nosotros"
            popupClassName="menu-blue"
            className={selectedKeys.includes("NOSOTROS") ? "submenu-open" : ""}
            theme="dark"
          >
            <Menu.Item key="23">
              <Link
                to="/quienes-somos"
                id="HEADER007"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                  triggerAntLayoutScroll();
                }}
              >
                ¿Quiénes somos?
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link
                to="/talleres-afiliados"
                id="HEADER006"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                  triggerAntLayoutScroll();
                }}
              >
                Talleres afiliados
              </Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link
                to="/preguntas-frecuentes"
                id="HEADER008"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                  triggerAntLayoutScroll();
                }}
              >
                Preguntas frecuentes
              </Link>
            </Menu.Item>
            <Divider
              dashed={true}
              style={{ borderColor: "white", margin: "12px 0px" }}
            />
            <div style={{ margin: "30px 0px 22px 0px" }}>Contáctanos</div>
            <Menu.Item
              key="2"
              icon={
                <img
                  src={whatsappIco}
                  alt=""
                  className="img-icon-15 white-icon"
                />
              }
            >
              <a
                id="HEADER029"
                href="https://wa.me/+525564359958"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                }}
              >
                Escribir por WhatsApp
              </a>
            </Menu.Item>
            <Menu.Item
              key="5"
              icon={
                <img
                  src={phoneIco}
                  alt=""
                  className="img-icon-15 white-icon"
                  loading="lazy"
                />
              }
            >
              <a
                id="HEADER030"
                href="tel:5522820800"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                }}
              >
                Llamar al 55 2282 0800
              </a>
            </Menu.Item>
            <Menu.Item
              key="8"
              icon={
                <img
                  src={MailIco}
                  alt=""
                  className="img-icon-15 white-icon"
                  loading="lazy"
                  style={{ width: 23, height: 18 }}
                />
              }
            >
              <a
                href="mailto:soporte@carker.com.mx"
                id="HEADER031"
                onClick={(e) => {
                  gtmSetId(e.currentTarget);
                }}
              >
                Enviar correo electrónico
              </a>
            </Menu.Item>
          </SubMenu>

          {user && (
            <Menu.Item key="VEHICULOS">
              <Link
                to="/vehiculos"
                id="HEADER023"
                onClick={(ev) => gtmSetId(ev.currentTarget)}
              >
                Vehículos
              </Link>
            </Menu.Item>
          )}

          {user && (
            <Menu.Item key="CONSULTAS">
              <Link
                to="/chats"
                id="HEADER024"
                onClick={(ev) => gtmSetId(ev.currentTarget)}
              >
                Chats
              </Link>
            </Menu.Item>
          )}

          {user && (
            <Menu.Item key="CITAS">
              <Link
                to="/citas"
                id="HEADER025"
                onClick={(ev) => gtmSetId(ev.currentTarget)}
              >
                Citas
              </Link>
            </Menu.Item>
          )}
          {user && (
            <Menu.Item
              key="28"
              className=""
              aria-label="Menú de notificaciones"
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: 12,
                }}
              >
                <NotificationBell />
              </div>
            </Menu.Item>
          )}
          <SubMenu
            key="12"
            icon={
              user && user.photoUrl.trim() !== "" && sasToken ? (
                <Avatar
                  aria-label="Ver opciones de usuario"
                  style={{
                    width: "28px",
                    height: "28px",
                  }}
                  src={`${user?.photoUrl}?${sasToken}`}
                  onError={() => true}
                />
              ) : (
                <div style={{ height: 52 }}>
                  <CkUserIcon aria-label="Ver opciones de usuario" />
                </div>
              )
            }
            popupClassName="menu-blue left"
          >
            {user ? (
              <>
                <Menu.Item
                  key="menu-user-profile"
                  className="menu-user-profile"
                >
                  <Link
                    to="/perfil"
                    id="HEADER021"
                    onClick={(e) => {
                      gtmSetId(e.currentTarget);
                      triggerAntLayoutScroll();
                    }}
                  >
                    Editar perfil
                  </Link>
                </Menu.Item>
                <Menu.Item key="menu-user-signout">
                  <div
                    id="HEADER022"
                    onClick={(e) => {
                      logout();
                      gtmSetId(e.currentTarget);
                      window.location.href = "/";
                      triggerAntLayoutScroll();
                    }}
                    style={{ color: "white" }}
                  >
                    Cerrar sesión
                  </div>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item className="div-fill" key="15">
                  <div
                    id="HEADER011"
                    onClick={(e) => {
                      setLoginModal(true);
                      gtmSetId(e.currentTarget);
                      triggerAntLayoutScroll();
                    }}
                  >
                    Inicia sesión
                  </div>
                </Menu.Item>
                <Menu.Item key="16">
                  <div
                    id="HEADER016"
                    onClick={(e) => {
                      setSignupModal(true);
                      gtmSetId(e.currentTarget);
                      triggerAntLayoutScroll();
                    }}
                  >
                    Crea tu cuenta
                  </div>
                </Menu.Item>
              </>
            )}
          </SubMenu>
        </Menu>
      </div>
    </Header>
  );
};

export default memo(DesktopNavbar);

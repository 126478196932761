// App.tsx or your main component file
import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routesConfig from '../../../routes';
import { User } from '../../../models';

const Routes : FC<{
  user: User;
}> = ({ user }) =>  (
    <Switch>
      {routesConfig.map((route, index) => {
        if (route.redirect) {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              render={() => <Redirect to={route.redirect} />}
            />
          );
        }
        return (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={() => (
            route.protected && !user
              ? <Redirect to="/404" />
              : <route.component 
                  {...route.props}
                />
          )}
          />
        )}
      
      )}
      {/* Fallback route for 404 page */}
      <Redirect to="/404" />
    </Switch>
  );

export default Routes;
